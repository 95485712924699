@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: FuturaCondensedOutline;
  src: url("./assets/font/futura_condensed_extra_bold_outline.otf");
}

@font-face {
  font-family: FuturaBold;
  src: url("./assets/font/futura_std_bold.otf");
}

@font-face {
  font-family: FuturaBook;
  src: url("./assets/font/futura_std_book.otf");
}

@font-face {
  font-family: FuturaCondensedExtraBold;
  src: url("./assets/font/futura_std_condensed_extra_bd.otf");
}

@font-face {
  font-family: FuturaCondensedLight;
  src: url("./assets/font/futura_std_condensed_light.otf");
}

@font-face {
  font-family: FuturaCondensed;
  src: url("./assets/font/futura_std_condensed.otf");
}

@font-face {
  font-family: FuturaHeavy;
  src: url("./assets/font/futura_std_heavy.otf");
}

@font-face {
  font-family: FuturaMedium;
  src: url("./assets/font/futura_std_medium.otf");
}

@font-face {
  font-family: SfProBold;
  src: url("./assets/font/sf_pro_display_bold.ttf");
}

@font-face {
  font-family: SfDisplayMedium;
  src: url("./assets/font/sf_pro_display_medium.ttf");
}
@font-face {
  font-family: SfDisplayRegular;
  src: url("./assets/font/sf_pro_display_regular.ttf");
}
@font-face {
  font-family: SfProSemiBold;
  src: url("./assets/font/sf_pro_text_semibold.ttf");
}

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: FuturaCondensed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

h1, h2, h3, p, span {
   font-family: FuturaCondensed;
}

input[type="text"] {
  font-family: FuturaCondensed;
}

html {
  font-family: FuturaCondensed;
}

code {
  font-family: FuturaCondensed;
}
