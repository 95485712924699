.user-table-view {
  padding: 50px;
}

.calendar-container {
  position: absolute;
  bottom: 5%;
  right: 2%;
}

.total-payment-popup-container{
  padding: 20px;
}
