.manage-resource-container {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 2%;
  margin-bottom: 2%;
}

.question-container-column {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-top: 10px;
  padding: 3%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.total-no-of-question {
  margin-top: 10px;
}

.action-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
