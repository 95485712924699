.chapter-container {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 2%;
  margin-bottom: 2%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.chapter-title-container {
  width: 100%;
  padding: 3%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
}

.add-question-content {
  width: 40%;
}

.options-container {
  margin-top: 2%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
}

.button-save-add-question {
  height: 40px;
  width: 20%;
  border-radius: 4px;
  background-color: #55c2da;
  color: white;
  margin-top: 20px;
  font-size: small;
  font-family: FuturaCondensed;
  letter-spacing: 0.2px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border: none;
}

.line-container {
  width: 2px;
  height: 80vh;
  margin-left: 50px;
  background-color: black;
}

.added-questions-container {
  width: 60%;
  height: 95%;
  overflow-y: scroll;
}

.questions-added-list {
  margin-left: 5%;
  padding: 3%;
  background-color: red;
  border-radius: 10px;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 2%;
  margin-bottom: 2%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.add-question-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.add-question-container {
  width: 35%;
  overflow-y: scroll;
}

.add-question-dialog {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 85%;
}

.collapse-card {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 2%;
}

.typography-style {
  color: black;
  text-transform: uppercase;
  margin-left: 2%;
}

.video-container {
  display: grid;
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 10px;
  grid-template-rows: repeat(auto-fit, 1fr);
  grid-template-columns: repeat(2, 1fr);
}

.video-item {
  padding: 10px;
  background: linear-gradient(
    45deg,
    rgba(109, 128, 254, 0.8) 0%,
    rgba(35, 210, 253, 0.8) 100%
  );

  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1), 0 5px 5px rgba(0, 0, 0, 0.2);
}

.file-container {
  display: grid;
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 10px;
  grid-template-rows: repeat(auto-fit, 1fr);
  grid-template-columns: repeat(2, 1fr);
}

.file-item {
  padding: 10px;
  background: linear-gradient(
    45deg,
    rgba(255, 153, 139, 1) 0%,
    rgba(255, 109, 136, 1) 100%
  );
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1), 0 5px 5px rgba(0, 0, 0, 0.2);
}

.button-style-attach {
  width: 20%;
  align-self: flex-end;
  border: none;
  background-color: #55c2da;
  height: 35px;
  font-family: FuturaCondensed;
  text-transform: uppercase;
  font-size: 15px;
}

.button-style-attach:focus {
  outline: none;
}

.button-style-attach:hover {
  scale: 1.1;
  transition: 0.1s;
}

.button-style-upload {
  width: 20%;
  align-self: flex-end;
  border: none;
  background-color: #5226cbcc;
  height: 35px;
  font-family: FuturaCondensed;
  text-transform: uppercase;
  font-size: 15px;
}

.button-style-upload:focus {
  outline: none;
}

.button-style-upload:hover {
  scale: 1.1;
  transition: 0.1s;
}

.button-style-done {
  width: 75px;
  border: none;
  background-color: #5226cbcc;
  height: 35px;
  font-family: FuturaCondensed;
  text-transform: uppercase;
  color: white;
  border-radius: 5px;
  font-size: 15px;
}

.button-style-done:focus {
  outline: none;
}

.button-style-done:hover {
  scale: 1.1;
  transition: 0.1s;
}

.progress-bar-container {
  display: flex;
  flex-direction: column;
  font-family: FuturaCondensed;
  align-items: center;
  height: auto;
}

.lottie-style {
  display: flex;
  justify-content: center;
}

.dialog-content-container {
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
}

.chapter-container:hover .heading-container {
  color: white;
}

.chapter-container:hover {
  cursor: pointer;
  background-color: black;
  transition: 0.5s;
}

.chapter-content-preview {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  height: 88%;
}

.file-list-container {
  width: 50%;
  height: 100%;
  margin-left: 20px;
  overflow-y: scroll;
}

.questions-list-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.question-content {
  padding: 3%;
  border-radius: 10px;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 2%;
  margin-bottom: 2%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.question-style {
  font-family: FuturaCondensed;
  display: flex;
  align-items: center;
}

.file-title-style {
  font-family: FuturaCondensed;
  display: flex;
  justify-items: center;
  margin-top: 20px;
}

.options-style {
  display: flex;
  align-items: center;
}

.file-list {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
  overflow-x: scroll;
  padding-bottom: 10px;
}

.file-list-for-video {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-bottom: 10px;
  overflow-x: scroll;
}
.file-list-for-video::-webkit-scrollbar {
  height: 4px; /* height of horizontal scrollbar ← You're missing this */
  width: 4px; /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}

.file-list::-webkit-scrollbar {
  height: 4px; /* height of horizontal scrollbar ← You're missing this */
  width: 4px; /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}

.files-card-container {
  width: 90%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: rgba(255, 255, 0, 0.399);
  display: flex;
  border: none;
  border-radius: 10px;
}

.files-container {
  margin-left: 15px;
  margin-right: 10px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.5) 0px 0px 0px 1px;
  border: none;
  border-radius: 10px;
  width: 250px;
  height: 250px;
  background-color: black;
}

.files-container-pdf-image {
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.5) 0px 0px 0px 1px;
  border: none;
  border-radius: 10px;
  width: 200px;
  height: 200px;
  background-color: black;
}

.font-change-style {
  font-family: FuturaCondensed;
}

.close-icon {
  display: flex;
  justify-content: center;
  width: 30px;
  height: 30px;
}
.close-icon:hover {
  cursor: pointer;
}

.deleteIconQuestions {
  display: flex;
  justify-content: flex-end;
}

.copy-button {
  align-self: flex-end;
}

.input-error-container {
  color: red;
  font-size: 15px;
}
