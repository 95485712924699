.tab-list {
  margin-top: 20px;
  margin-left: 50px;
  margin-right: 50px;
   font-family: FuturaCondensed;
}
.tab-style:focus{
   outline: none;
}

.tab-style{
  font-family: FuturaCondensed;
  font-size: 15px;
}

.font-change-style{
    font-family: FuturaCondensed;
}
