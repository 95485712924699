.home-container {
  display: flex;
  flex-direction: column;

}

.home {
  display: flex;
  width: 100%;
  font-size: 40px;
  font-weight: bold;
  color: #313639;
    margin: 0;
  padding-top: 5%;
  padding-left: 5%;
}

.doughtnut-style {
  margin-left: 5%;
}

.revenue-title{
  margin-left: 3%;
}

.revenue-title h2{
  font-family: FuturaCondensed;
  text-transform: uppercase;
  margin-top: 20px;
}

.revenue {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 40px;
}

.revenue-item {
  padding: 20px;
  border-radius: 10px;
  width: 45%;
  background-color: white;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}
