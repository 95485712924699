.manage-carousel-button-container{
    position: absolute;
    bottom: 5%;
    right: 5%;
}

.create-carousel-dialog{
  display: flex;
  flex-direction: column;
  width: 600px;
}

.carousel-dialog-content {
  display: flex;
  justify-content: end;
}

.carousel-list-container {
  display: grid;
  margin-top: 10px;
  width: 100%;
  gap: 50px;
  grid-template-rows: repeat(auto-fit, 1fr);
  grid-template-columns: repeat(3, 1fr);
}

.carousel-list {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
   box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.carousel-list:hover{
   box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

.image-container{
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  height: 250px;
}

.delete-icon-box{
margin-right: 5%;
margin-bottom: 5%;
}

.carousel-title-description{
  margin: 5%;
}

.carousel-image-preview-container{
  height: 250px;
}
.close-icon{
  display: flex;
  justify-content: center;
  width: 30px;
  height: 30px;
}
.close-icon:hover{
  cursor: pointer;
}