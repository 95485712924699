.form-create-course {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 2%;
  margin-bottom: 2%;
  display: flex;
  flex-direction: column;
}

.heading {
  font-size: 20px;
  font-family: FuturaCondensed;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-top: 2%;
  margin-bottom: 1%;
}

.input-title {
  padding: 1%;
  border: 2px solid #733de4;
  border-radius: 5%;
  outline: none;
}

.file-thumbnail {
  margin-top: 2%;
}

.file-attachment {
  margin-top: 2%;
}
.button-save {
  width: 20%;
  height: 40px;
  text-align: center;
  margin-top: 2%;
  align-self: flex-end;
  border: none;
  border-radius: 4px;
  background-color: #33b249;
  font-family: FuturaCondensed;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  cursor: pointer;
  border: 0px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.button-save:focus {
  outline: none;
}

.button-save:hover {
  scale: 1.1;
  transition: 0.2s;
}

.thumbnail-container {
  width: 100%;
  height: 40vh;
  margin-top: 2%;
  border-radius: 10px;
  display: flex;
  border-style: dotted;
  justify-content: center;
  align-items: center;
}

.attachment-container {
  display: flex;
  flex-direction: column;
  border-style: groove;
  justify-content: center;
  border-radius: 10px;
  align-items: center;
  margin-top: 20px;
}

.upload-thumbnail-text {
  font-size: 15px;
  font-family: FuturaCondensed;
}

.thumbnail-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.initial-thumbnail {
  width: 140px;
  height: 100%;
}

.upload-thumbnail-button {
  width: 20%;
  height: 40px;
  text-align: center;
  margin-top: 2%;
  align-self: flex-end;
  border: none;
  border-radius: 4px;
  background-color: #55c2da;
  font-family: FuturaCondensed;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  cursor: pointer;
  border: 0px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.upload-thumbnail-button:focus {
  outline: none;
}

.upload-thumbnail-button:hover {
  scale: 1.1;
  transition: 0.2s;
}

.add-pricing-title {
  font-family: FuturaCondensed;
  font-size: 25px;
  text-transform: uppercase;
}

.add-pricing-title-add-price {
  font-family: FuturaCondensed;
  font-size: 25px;
  margin-top: 10px;
  text-transform: uppercase;
}

.button-container-add-price {
  width: 100%;
  display: flex;
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.button-style-cancel-add-price {
  height: 40px;
  width: 20%;
  border-radius: 4px;
  background-color: #5dbea3;
  color: white;
  margin-top: 20px;
  font-size: small;
  font-family: FuturaCondensed;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: white;
  margin-right: 10px;
  cursor: pointer;
  border: none;
}

.button-style-done-add-price {
  height: 40px;
  width: 20%;
  border-radius: 4px;
  background-color: #55c2da;
  color: white;
  margin-top: 20px;
  font-size: small;
  font-family: FuturaCondensed;
  letter-spacing: 0.2px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border: none;
}

.price-item-container {
  display: grid;
  margin-top: 10px;
  width: 100%;
  gap: 10px;
  grid-template-rows: repeat(auto-fit, 1fr);
  grid-template-columns: repeat(4, 1fr);
}

.price-item-0 {
  background: linear-gradient(
    45deg,
    rgba(109, 128, 254, 0.8) 0%,
    rgba(35, 210, 253, 0.8) 100%
  );
  padding: 20px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1), 0 5px 5px rgba(0, 0, 0, 0.2);
}

.price-item-1 {
  background: linear-gradient(
    45deg,
    rgba(255, 153, 139, 1) 0%,
    rgba(255, 109, 136, 1) 100%
  );
  padding: 20px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1), 0 5px 5px rgba(0, 0, 0, 0.2);
}

.price-item-2 {
  background: linear-gradient(
    45deg,
    rgba(9, 175, 232, 1) 0%,
    rgba(41, 244, 153, 1) 100%
  );
  padding: 20px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1), 0 5px 5px rgba(0, 0, 0, 0.2);
}

.price-item-3 {
  background: linear-gradient(
    45deg,
    rgba(112, 124, 255, 1) 0%,
    rgba(250, 129, 232, 1) 100%
  );
  padding: 20px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1), 0 5px 5px rgba(0, 0, 0, 0.2);
}

.heading-title {
  font-size: 20px;
  font-family: FuturaCondensed;
  text-transform: uppercase;
}

.value-title {
  font-size: 15px;
  font-family: FuturaCondensed;
  text-transform: uppercase;
}

.delete-icon-box {
  display: flex;
  justify-content: end;
}

.delete-icon-box:focus {
  display: flex;
  justify-content: end;
  outline: none;
}

.short-course-checkbox{
  display: flex;
  flex-direction: row;
  justify-content: end;
}
