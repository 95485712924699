.image-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  text-decoration: none;
  margin-bottom: 5px;
  background: linear-gradient(
    45deg,
    rgb(224, 120, 16) 0%,
    rgba(255, 109, 136, 1) 100%
  );
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1), 0 5px 5px rgba(0, 0, 0, 0.2);
}

.preview-files-for-question-popup-dialog{
    width: 500px;
}

.text-style-image-url{
    color: black;
    text-decoration: none;
}

.image-item:hover{
    text-decoration: none;
}
