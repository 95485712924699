.delete-message{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chapter-name-style{
    color: blue;
    margin-left: 5px;
    margin-right: 5px;
}