.course-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 10px;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 2%;
  margin-bottom: 2%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.content-text {
  padding: 3%;
  width: 80%;
}

.manage-button {
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 50px;
  border-radius: 4px;
  background-color: #733de4;
  color: white;
  font-family: Bahnschrift SemiBold;
  cursor: pointer;
  border: 0px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.manage-button-container {
  display: flex;
  align-items: center;
  justify-content: end;
}

.delete-icon-container {
  margin-left: 5%;
  margin-right: 10%;
}

.infinite-scroll-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.create-course-button {
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 40px;
  letter-spacing: 0.5px;
  font-family: FuturaCondensedLight;
  width: 120px;
  border-radius: 5px;
  background-color: dodgerblue;
  text-transform: uppercase;
  color: white;
  font-weight: 500;
  cursor: pointer;
  border: 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.create-course-container {
  height: 10%;
  position: absolute;
  right: 0;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(237, 231, 231);
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(166, 163, 163);
  border-radius: 2px;
}

.delete-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 300px;
  margin-top: -50px;
}

.button-container-delete {
  width: 40%;
  height: 12%;
  position: absolute;
  right: 5%;
  bottom: 0;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-around;
}

.button-style-no {
  width: 40%;
  border-radius: 4px;
  background-color: #733de4;
  color: white;
  cursor: pointer;
  border: none;
  font-size: small;
  letter-spacing: 2px;
  font-family: FuturaMedium;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
}
.button-style-yes {
  width: 40%;
  border-radius: 4px;
  background-color: red;
  color: white;
  font-size: small;
  font-family: FuturaMedium;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border: none;
}

.delete-message {
  font-family: FuturaCondensed;
  text-transform: uppercase;
  color: #363936f0;
  font-weight: bold;
}

.button-focus:focus {
  border: none;
  outline: none;
}

.button-style-no:focus {
  border: none;
  outline: none;
}
.button-style-yes:focus {
  border: none;
  outline: none;
}
.course-share-container{
  padding: 12px;
  border: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  justify-content: space-evenly;
}

