.login-background-lottie {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  z-index: -1;
}

.login-screen-container {
  height: 100%;
  width: 100vw;
}

.login-form-container {
  width: 350px;
  height: 400px;
  background-color: white;
  position: absolute;
  right: 10%;
  top: 15%;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: url("../assets/image/login_background.jpg");
  background-size: cover;
 box-shadow: 0 19px 38px rgba(32, 31, 31, 0.805), 0 15px 12px rgba(0,0,0,0.22);
}

.login-form-container:before {
  position: absolute;
  content: "";
  top: -0.25em;
  left: -0.25em;
  background: linear-gradient(51deg, #f490ff, #be0786, #4eafff, #700099);
  z-index: -1;
  width: calc(100% + 0.5em);
  height: calc(100% + 0.5em);
  border-radius: 5px;
  animation: gradient 5s ease infinite;
  background-size: 350% 350% !important;
  box-shadow: 0 8px 12px #4eafff88;
  transition: 0.5s ease-in-out;
}

.login-form-container:hover:before {
  animation: gradient 5s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
    box-shadow: 0px 0px 15px #4eafff;
  }

  50% {
    background-position: 100% 50%;
    box-shadow: 0 0px 10px #4eafff;
  }

  100% {
    background-position: 0% 50%;
    box-shadow: 0px 0px 20px #4eafff;
  }
}

.text-font{
  font-family: FuturaCondensed;
}

.logo-container{
  display: flex;
  flex-direction: row;
  margin-left: 5%;
  margin-top: 2%;
}

.content-container-title{
  margin-left: 10%;
  margin-top: 5%;
  width: 40%;
}


.login-logo-title {
  color: red;
  align-self: center;
  margin-left: 20px;
  font-size: 50px;
}

.email-password-error{
  color: red;
}
