.sidebar {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: none;
  background-color: rgba(255, 255, 255, 0.95);
  color: #333;
  font-family: sans-serif;
  line-height: 1.5;
  padding: 1rem 2rem;
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .sidebar {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(3px);
    background-color: rgba(255, 255, 255, 0);
  }
  .warning {
    display: none;
  }
}

.sidebar-list {
  padding: 0;
  margin-top: 20%;
}

.logo-name {
  display: flex;
  margin-top: 5%;
  margin-left: -15%;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 10%;
}

.divider {
  height: 0.5px;
  background-color: white;
  margin-left: 10%;
  margin-right: 10%;
  opacity: 0.5;
}

.logo-title {
  color: rgba(255, 0, 0, 0.767);
  font-family: "FuturaCondensed";
  font-size: xx-large;
  margin-left: 10px;
}

.list-item {
  color: white;
  padding: 10px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 10%;
  letter-spacing: 0.2px;
  margin-right: 10%;
  font-family: FuturaCondensed;
  text-transform: uppercase;
}

.list-item-active {
  color: #36454f;
  padding: 10px;
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #f6f9fc;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 10%;
  margin-right: 10%;
  font-family: FuturaCondensed;
  text-transform: uppercase;
}

.list-item:hover {
  background-color: #f6f9fc;
  cursor: pointer;
  color: #36454f;
  font-weight: bold;
  border-radius: 5px;
  margin-left: 10%;
  margin-right: 10%;
}

.list-item:hover .icon {
  color: black;
}

.icon {
  width: 30%;
  display: flex;
  color: white;
  justify-content: end;
}

.icon-active {
  width: 30%;
  display: flex;

  justify-content: end;
}

.title {
  width: 70%;
  display: flex;
  font-size: 20px;
  justify-content: start;
  margin-left: 15px;
}

.navbar-child {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
  overflow-y: auto;
}

.copyright-phynlabz-tech-pvt-ltd{
  color: rgba(224, 210, 210, 0.623);
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
