.publish-successful-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 300px;
}

.published-message{
    color: green
}

.button-published-container{
    display: flex;
    justify-content: center;
}
