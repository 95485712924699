.section-list {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 2%;
  margin-bottom: 2%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.section-content {
  width: 90%;
  padding: 3%;
  margin-left: 5%;
}

.plus-icon {
  position: absolute;
  top: 85%;
  right: 5%;
}

.plus-icon:hover {
  cursor: pointer;
}

.button-container {
  width: 30%;
  height: 8%;
  position: absolute;
  top: 85%;
  right: 5%;
  display: flex;
  justify-content: space-around;
}

.button-style {
  width: 40%;
  border-radius: 4px;
  background-color: #733de4;
  color: white;
  font-family: Bahnschrift SemiBold;
  cursor: pointer;
  border: 0px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.button-style:hover {
  transition: 0.2s;
  scale: 1.1;
}

.button-style:focus {
  outline: none;
}

.heading-style {
  color: black;
  font-family: "Nunito Sans";
  font-weight: bold;
  font-size: 20px;
}

.input-style {
  margin-top: 2%;
  margin-bottom: 2%;
  border-radius: 4px;
}

.text-area {
  margin-top: 10px;
  margin-bottom: 10px;
}

.manage-section-button-container {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}

.section-dialog-title {
  display: flex;
  flex-direction: column;
  width: 600px;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
}

.section-dialog-content {
  display: flex;
  justify-content: end;
}

.button-container {
  width: 100%;
  display: flex;
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-bottom: 5%;
  background-color: red;
}

.button-style-manage-section {
  height: 40px;
  width: 20%;
  border-radius: 4px;
  background-color: #55c2da;
  color: white;
  margin-top: 20px;
  font-size: small;
  margin-left: 5%;
  font-family: FuturaCondensed;
  letter-spacing: 0.2px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border: none;
}

.button-style-manage-section:focus {
  outline: none;
}

.button-style-manage-section:hover {
  scale: 1.1;
  transition: 0.2s;
}

.section-create-chapter-dialog {
  display: flex;
  flex-direction: column;
  width: 600px;
}

.add-image {
  display: flex;
  justify-content: end;
  margin-top: 10px;
}

.section-name-style {
  color: blue;
}
