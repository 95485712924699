.copy-section-popup-container{
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.copy-section-popup-message{
    align-self: center;
}

.copy-section-popup-cancel-icon:hover{
    cursor: pointer;
}

.copy-section-popup-chapter-name{
    color: blue;
    align-self: center;
}
