.uploadFilesForQuestionPopupContainer {
  width: 500px;
}

.file-type-container {
  display: flex;
  flex-direction: column;
}

.upload-file-type-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.tooltip-button-container {
  align-self: flex-end;
}

.file-item-upload-files-for-question-popup {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 5px;
  background: linear-gradient(
    45deg,
    rgba(255, 153, 139, 1) 0%,
    rgba(255, 109, 136, 1) 100%
  );
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1), 0 5px 5px rgba(0, 0, 0, 0.2);
}

.upload-files-for-question-popup-button-container {
  align-self: flex-start;
}
