.email-area {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 2%;
  margin-bottom: 2%;
}

.text-area{
    margin-top: 2%;
}
