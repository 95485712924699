.publish-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 300px;
}

.course-name-style {
  color: blue;
}

.button-container-publish {
  display: flex;
  justify-content: space-between;
}

.end-button {
  display: flex;
  justify-content: flex-end;
}
